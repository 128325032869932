type PlanOfferTypeMapping = {
  [index: string]: any
}

export const planOfferTypeMapping: PlanOfferTypeMapping = {
  'CC': ['monthly_28d-trial', 'annual_28d-trial'],
  'CC7': ['monthly_7d-trial', 'annual_28d-trial'],
  'CC14': ['monthly_14d-trial', 'annual_28d-trial'],
  'SKIP': ['monthly_28d-trial'],
  'SKIP2': ['monthly_60d-trial'],
  'SKIP3': ['annual_0d-trial'],
  'GIFT-1M': ['monthly_0d-trial'],
  'GIFT-3M': ['monthly_0d-trial'],
  'GIFT-6M': ['monthly_0d-trial'],
  'GIFT-12M': ['monthly_0d-trial'],
  'PREPAY': ['monthly_28d-paid-trial'],
  'PREPAY-70': ['monthly_28d-paid-trial'],
  'PREPAY-75': ['monthly_28d-paid-trial'],
  'PREPAY-80': ['monthly_28d-paid-trial'],
  'PREPAY-90': ['monthly_28d-paid-trial'],
  'LT-BASIC': ['lifetime-basic_0d-trial'],
  'LT-UNLIMITED': ['lifetime-unlimited_0d-trial'],
  'LT-ET': ['lifetime-standard-80_0d-trial'],
  'LT-EF': ['lifetime-standard-85_0d-trial'],
  'LT-NY': ['lifetime-standard-90_0d-trial'],
  'LT-NT': ['lifetime-standard-93_0d-trial'],
  'LT-NF': ['lifetime-standard-95_0d-trial'],
  'GIFT-LT': [],
};
